import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Styles
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { Row, Col } from "../components/layout/Grid"
import SEO from "../components/layout/SEO"
import Container from "../components/layout/Container"
import Divider from "../components/layout/Divider"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import LazyImage from "../components/helpers/LazyImage"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface ITrack {
  title: string
  image: {
    url: string
  }
  date: string
  audioFile: {
    url: string
  }
}

interface IProps {
  data: {
    metaTitle?: string
    metaDescription?: string
    heroTitle: string[]
    heroText: {
      html: string
    }
    tracks: ITrack[]
  }
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.accent2,
  },
}

const RadioPage = ({ data }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO
        noIndex={true}
        title={data.metaTitle}
        description={data.metaDescription}
      />

      {data && (
        <Container>
          <Hero>
            <HeroRow>
              <Col xxs={8} m={7} mOffset={1}>
                {data.heroTitle && (
                  <HeroTitleWrapper>
                    <AnimatedTextScrollWrapper
                      text={data.heroTitle}
                      textStyles={textStyles.headingL}
                    />
                  </HeroTitleWrapper>
                )}
              </Col>

              <Col xxs={8} m={6} mOffset={1}>
                {data.heroText && data.heroText.html && (
                  <AnimatedScrollWrapper>
                    <HeroText
                      dangerouslySetInnerHTML={{ __html: data.heroText.html }}
                    />
                  </AnimatedScrollWrapper>
                )}
              </Col>
            </HeroRow>
          </Hero>

          {data.tracks && (
            <TrackList>
              {data.tracks.map((track, index: number) => (
                <Track key={`track--${index}`}>
                  <DividerRow>
                    <Col xxs={8} m={15} mOffset={1}>
                      <AnimatedScrollWrapper>
                        <Divider color={colors.white} />
                      </AnimatedScrollWrapper>
                    </Col>
                  </DividerRow>

                  <Row>
                    <Col xxs={8} m={7} mOffset={1}>
                      {track.image && <TrackImage src={track.image.url} />}
                    </Col>

                    <Col xxs={8} m={6} mOffset={1}>
                      <TrackContent>
                        {track.title && (
                          <AnimatedScrollWrapper>
                            <TrackTitle>{track.title}</TrackTitle>
                          </AnimatedScrollWrapper>
                        )}
                        {track.date && (
                          <AnimatedScrollWrapper>
                            <TrackDate>&mdash; {track.date}</TrackDate>
                          </AnimatedScrollWrapper>
                        )}
                      </TrackContent>

                      {track.audioFile && (
                        <AnimatedScrollWrapper>
                          <TrackAudio controls src={track.audioFile.url}>
                            Your browser does not support the
                            <code>audio</code> element.
                          </TrackAudio>
                        </AnimatedScrollWrapper>
                      )}
                    </Col>
                  </Row>
                </Track>
              ))}
            </TrackList>
          )}
        </Container>
      )}
    </Wrapper>
  )
}

// General styles
const DividerRow = styled(Row)`
  margin-bottom: 8px;
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.white};
`

// Hero
const Hero = styled.div`
  margin-bottom: calc(2 * var(--base-column-size));
  /* duplicate style because of IE */
  padding-top: 5vh;
  padding-top: var(--base-column-size);
  color: ${colors.white};
`

const HeroRow = styled(Row)`
  width: 100%;
  /* duplicate style because of IE */
  margin-bottom: 15vh;
  margin-bottom: calc(2 * var(--base-column-size));
`

const HeroTitleWrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const HeroText = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

// Tracks
const TrackList = styled.ul`
  margin-left: 0;
  margin-bottom: calc(var(--base-column-size) * 2);
  padding: 0;
  list-style: none;
`

const Track = styled.li`
  margin-left: 0;

  &:not(:last-of-type) {
    margin-bottom: var(--base-column-size);
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const TrackImage = styled(LazyImage)``

const TrackContent = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
  padding-top: calc(var(--base-column-size) / 2);

  ${mq.from.M`
    padding-top: 0;
  `}
`

const TrackTitle = styled.h2`
  ${textStyles.headingS};
  margin-bottom: 0;
`

const TrackDate = styled.div``

const TrackAudio = styled.audio`
  width: 100%;
`

export default RadioPage
