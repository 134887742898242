import React from "react"
import { graphql } from "gatsby"

// Components
import RadioPage from "../pageViews/radioPageView"

// Utils
const formatTrackList = (tracks: any) => {
  return tracks.map((item: any) => {
    return {
      title: item.track_title,
      image: item.track_image,
      date: item.track_date,
      audioFile: item.track_audio_file,
    }
  })
}

const RadioPageContainer = ({ data }: any) => {
  if (!data.radioPage) {
    return null
  }

  const rawData = data.radioPage.data

  const formattedData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    heroTitle: rawData.hero_title.raw.map((line: any) => line.text),
    heroText: rawData.hero_text,
    tracks: formatTrackList(rawData.tracks),
  }

  return <RadioPage data={formattedData} />
}

export const query = graphql`
  query GetRadioPage {
    radioPage: prismicRadioPage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        hero_text {
          html
        }
        hero_title {
          raw {
            text
          }
        }
        tracks {
          track_title
          track_image {
            url
          }
          track_date(formatString: "DD/MM/YYYY")
          track_audio_file {
            url
          }
        }
      }
    }
  }
`

export default RadioPageContainer
